<template>
  <div style="background-color: #ffffff;">
    <table cellpadding="0" cellspacing="0" v-if="tableData.type === 1">
      <tr style="text-align: center;">
        <th colspan="10">销售单</th>
      </tr>
      <tr>
        <td>客户名称</td>
        <td colspan="3">{{ tableData.client_name }}</td>
        <td>单据日期</td>
        <td colspan="3">{{ tableData.create_time | unixToDate }}</td>
        <td>销售单号</td>
        <td colspan="3">{{ tableData.trade_sn }}</td>
      </tr>
      <tr>
        <td>联系人</td>
        <td colspan="3">{{ tableData.link_name }}</td>
        <td>电话</td>
        <td colspan="3">{{ tableData.link_phone }}</td>
        <td>付款状态</td>
        <td colspan="3">{{ tableData.pay_status === 0 ? '未付款'
          : tableData.pay_status === 1 ? '部分付款' : '已付款' }}</td>
      </tr>
      <tr>
        <td>客户地址</td>
        <td colspan="9">{{ tableData.client_addr }}</td>
      </tr>
      <tr class="card">
        <td>序号</td>
        <td>名称</td>
        <td>单价(面值)</td>
        <td>单位</td>
        <td>始号段</td>
        <td>末号段</td>
        <td>数量</td>
        <td>折扣率</td>
        <td>折扣金额</td>
        <td>金额</td>
      </tr>
      <template v-if="tableData.card_range_list && tableData.card_range_list.length">
        <tr class="card" v-for="(item, index) in tableData.card_range_list" :key="index">
          <td>{{ index + 1}}</td>
          <td>{{ item.card_name }}</td>
          <td>{{ item.card_value }}</td>
          <td>元</td>
          <td>{{ item.card_code_start }}</td>
          <td>{{ item.card_code_end }}</td>
          <td>{{ item.card_num }}</td>
          <td>{{ item.discount_ratio }}</td>
          <td>{{ item.discount_value }}</td>
          <td>{{ (item.card_num * item.card_value) - item.discount_value }}</td>
        </tr>
      </template>
      <tr class="card">
        <td>合计</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ tableData.card_num }}</td>
        <td></td>
        <td>{{ (tableData.total_price - tableData.after_discount_price).toFixed(2) }}</td>
        <td>{{ tableData.after_discount_price }}</td>
      </tr>
      <tr class="remark">
        <td>备注信息</td>
        <td colspan="9">{{ tableData.remark }}</td>
      </tr>
    </table>
    <table cellpadding="0" cellspacing="0" v-if="tableData.type === 2">
      <tr style="text-align: center;">
        <th colspan="10">销售退单</th>
      </tr>
      <tr>
        <td>客户名称</td>
        <td colspan="3">{{ tableData.client_name }}</td>
        <td>单据日期</td>
        <td colspan="3">{{ tableData.create_time | unixToDate }}</td>
        <td>销售退单号</td>
        <td colspan="3">{{ tableData.sn }}</td>
      </tr>
      <tr>
        <td>联系人</td>
        <td colspan="3">{{ tableData.link_name }}</td>
        <td>电话</td>
        <td colspan="3">{{ tableData.link_phone }}</td>
        <td>退款状态</td>
        <td colspan="3">{{ tableData.refund_status === 0 ? '未退款'
          : tableData.refund_status === 1 ? '部分退款' : '已退款' }}</td>
      </tr>
      <tr>
        <td>客户地址</td>
        <td colspan="9">{{ tableData.client_addr }}</td>
      </tr>
      <tr class="card">
        <td>序号</td>
        <td>名称</td>
        <td>单价(面值)</td>
        <td>单位</td>
        <td>始号段</td>
        <td>末号段</td>
        <td>数量</td>
        <td>折扣率</td>
        <td>折扣金额</td>
        <td>金额</td>
      </tr>
      <template v-if="tableData.card_range_list && tableData.card_range_list.length">
        <tr class="card" v-for="(item, index) in tableData.card_range_list" :key="index">
          <td>{{ index + 1}}</td>
          <td>{{ item.card_name }}</td>
          <td>{{ item.card_value }}</td>
          <td>元</td>
          <td>{{ item.card_code_start }}</td>
          <td>{{ item.card_code_end }}</td>
          <td>{{ item.card_num }}</td>
          <td>{{ item.discount_ratio }}</td>
          <td>{{ item.discount_value }}</td>
          <td>{{ (item.card_num * item.card_value) - item.discount_value }}</td>
        </tr>
      </template>
      <tr class="card">
        <td>合计</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ tableData.card_num }}</td>
        <td></td>
        <td>{{ tableData.total_price - tableData.after_discount_price }}</td>
        <td>{{ tableData.after_discount_price }}</td>
      </tr>
      <tr class="remark">
        <td>备注信息</td>
        <td colspan="9">{{ tableData.remark }}</td>
      </tr>
    </table>
    <div style="display: flex; justify-content: space-between;">
      <p style="width: 20%;">业务员：</p>
      <p style="width: 20%;">制单：</p>
      <p style="width: 25%;">审核：</p>
      <p style="width: 35%;">签字：</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Object,
      default: {},
      required: true
    }
  },
  watch: {
    tableData: {
      deep: true,
      handler(newVal, oldVal) {
        this.$forceUpdate()
      }
    }
  },
  name: "printTemplate"
}
</script>

<style scoped lang="scss">
table {
  border: none;
  border-bottom: 1px solid #000;
}
table tr td {
  border: 0.5px solid #000;
  border-bottom: none;
  border-right: none;
}
table tr td:last-of-type,
table tr th:last-of-type {
  border-right: 1px solid #000;
}
table tr th {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
table tr th:nth-child(2) {
  width: 0;
}
table {
  width: 100%;
  margin-bottom: 20px;
  tr {
    height: 30px;
    td {
      padding-left: 2px;
    }
  }
  .card {
    td {
      text-align: center;
      padding-left: 0;
    }
  }
  .remark {
    height: 80px;
  }
}
</style>
